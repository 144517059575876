/* ---------------------------------------------------------------- */
/* 26.Home Page Signup                                 
/* ---------------------------------------------------------------- */
.signup-form-wrap {
	transition: all 0.25s linear;
	box-shadow: 0 10px 20px rgba($color_black, 0.1);
	.signup-form-header {
		padding: 20px;
		color: $color_white;
		text-align: center;
		background: $color_primary;
		border-radius: 5px 5px 0 0;
		.signup-title {
			color: $color_white;
			font-size: 1.1rem;
			font-weight: 600;
		}
	}
	.signup-form-inner {
		padding: 30px;
		text-align: center;
		background: $color_white;
		border-radius: 0 0 5px 5px;
		.signup-form-group {
			position: relative;
			overflow: hidden;
			> span {
				position: absolute;
				top: 15px;
				right: 15px;
				font-size: 20px;
				color: $color_primary;
				border-radius: 0 5px 5px 0;
			}
			.signup-form-control {
				height: 60px;
				border: none;
				width: 100%;
				outline: none;
				font-size: 0.9rem;
				color: $color_black;
				margin-bottom: 20px;
				padding: 0 50px 0 20px;
				transition: all 0.25s ease;
				box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
				&:focus {
					outline: none;
					border: none;
					color: $color_black;
					box-shadow: 0 10px 26px 0 rgba($color_primary, 0.15);
				}
			}
		}
		.signup-form-btn {
			width: 100%;
			border:none;
			outline: none;
			display: block;
			border-radius: 5px;
			padding: 20px 40px;
			text-transform: capitalize;
			color: $color_white !important;
			background: $color_primary;
			transition: all 0.25s linear;
			box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
			&:hover {
				color: $color_white !important;
				background:  darken($color_primary,5%);
				box-shadow: 0 10px 30px rgba($color_primary, 0.2);
			}
		}
		.custom-control {
			display: flex;
			align-items: center;
			min-height: auto;
			line-height: 26px;
			margin-bottom: 20px;
			.custom-control-label {
				margin-left: 15px;
				padding-left: 10px;
				color: $color_black;
				&:before {
					background: $color_white;
					border: 1px solid $color_primary;
					box-shadow: none !important;
				}
			}
			.custom-control-input {
				&:checked {
					& ~ .custom-control-label {
						&:before {
							color: $color_white;
							border-color: $color_primary;
							background: $color_primary;
						}
					}
				}
			}
		}
	}
}
/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.signup-form-wrap {
		text-align: center;
		background-position: 100% 0;
	}
	.signup-form-bg {
		padding-top: 150px;
		padding-bottom: 100px;
		height: auto !important;
	}
	.signup-form-wrap {
		margin-top: 50px;
	}
}
/* ------------------------------------------------------------- */
/* Responsive Media Query
 * Extra small devices (portrait phones, less than 576px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 575.98px) {
	.signup-form-bg {
		padding-top: 150px;
		padding-bottom: 100px;
		height: auto !important;
	}
	.signup-form-header, .signup-form-inner {
		padding: 15px;
	}
}