/* ---------------------------------------------------------------- */
/* 35.Home Page Glitch Effect                              
/* ---------------------------------------------------------------- */
.glitch-img-banner {
    .overlay-glitch {
        top: 0;
        left: 0;
        z-index: 45;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        content: "";
        position: absolute;
        background: $color_prestige_blue;
    }
    .glitch-img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .container {
        position: relative;
        z-index: 56;
    }
    .down-scroll { 
        z-index: 58;
    }   
}