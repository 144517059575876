/* ---------------------------------------------------------------- */
/* 25.Breadcrumb                          
/* ---------------------------------------------------------------- */
.breadcrumb-section {
	padding: 150px 0;
	overflow: hidden;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
    .breadcrumb-content {
        position: relative;
        z-index: 77;
        .bread-crumb-title {
            font-size: 2.2rem;
            color: $color_white;
        }
        .breadcrumb-link {
            margin-top: 20px;
            li {
                color: $color_white;
                display: inline-block;
                &.active {
                    &:before {
                        content: "\f30b";
                        display: inline-block;
                        margin-right: 10px;
                        margin-left: 10px;
                        font-weight: 600;
                        color: lighten($color_secondary,20%);
                        font-family: "Font Awesome 5 Free";
                    }
                }
            }
        }
    }
}