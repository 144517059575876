/* ---------------------------------------------------------------- */
/* 05.Animations                                  
/* ---------------------------------------------------------------- */
@keyframes buttonEffectRight {
	0% {
		right: -100%;
	}
	100% {
		right: 100%;
	}
}
@keyframes imgJump {
	0% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(0);
	}
	41% {
		transform: translateY(-10px);
	}
	50% {
		transform: translateY(-5px);
	}
	50% {
		transform: translateY(-5px);
	}
	80% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes shadowPulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(21, 219, 150, 0.4);
		box-shadow: 0 0 0 0 rgba(21, 219, 150, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 40px rgba(21, 219, 150, 0);
		box-shadow: 0 0 0 40px rgba(21, 219, 150, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(21, 219, 150, 0);
		box-shadow: 0 0 0 0 rgba(21, 219, 150, 0);
	}
}
@keyframes stickyMenu {
	0% {
		margin-top: -120px;
		opacity: 0;
	}
	50% {
		margin-top: -64px;
		opacity: 0;
	}
	100% {
		margin-top: 0;
		opacity: 1;
	}
}
@keyframes preloader {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
@keyframes dashedRotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes iconPulse {
	0%{
		transform: scale(0.5);
		opacity: 0;
	}
	50%{
		opacity: 1;
	}	
	100% {
		opacity: 0;
		transform: scale(1);
	}
}	 
@keyframes spinnerLoad {
	1% {
		top: -15px;
	}
	2% {
		top: 10px;
	}
	5% {
		top: -20px;
	}
	10% {
		left: -10px;
	}
	20% {
	    top: -25px;
	}
	30% {
	    left: 15px;
		background: $color_secondary;
	}
	35% {
	    top: 5px;
	}
	45% {
	    top: -5px;
	}
	50% {
		top: -5px;
	}
	60% {
		top: -6px;
	}
	70% {
	    top: 6px;
	}
	80% {
	    top:6px;
	}
	60% {
	    top: -6px;
	}
}
@keyframes spinnerDelay {
	0%, 40%, 100% { 
		transform: scaleY(0.4);
	}  
	20% { 
		transform: scaleY(1.0);
	}
}