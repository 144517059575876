/* ---------------------------------------------------------------- */
/* 06.Form(Input, Comment)                             
/* ---------------------------------------------------------------- */
.custom-modal {
	z-index: 4444;
    .modal-content {
        background: transparent;
        .modal-header {
            padding: 20px 30px;
            background: $color_primary;
            border-radius: 5px 5px 0 0;
            .modal-title {
                color: $color_white;
                font-size: 1.1rem;
            }
            button {
                opacity: 1;
                span {
                    font-weight: 400;
                    color: $color_white;
                }
            }    
        }
        .modal-body {
            padding: 30px 30px 20px 30px;
            background: $color_white;
            border-radius: 0 0 5px 5px;
            .popup-form-group {
                position: relative;
                overflow: hidden;
                .popup-form-control {
                    height: 60px;
                    outline: none;
                    width: 100%;
                    border: none;
                    color: $color_black;
                    padding: 0 20px 0 50px;
                    margin-bottom: 20px;
                    transition: all 0.25s ease;
                    box-shadow: 0 10px 28px 0 $color_shadow;
                    &:focus{
                        outline: none;
                        border: none;
                        box-shadow: 0 23px 49px 0 $color_shadow;
                    }
                }
                > textarea.popup-form-control {
                    height: auto;
                    padding: 18px 50px;
                }
                > span {
                    position: absolute;
                    top: 22px;
                    left: 16px;
                    font-size: 16px;
                    color: $color_primary;
                    border-radius: 0 5px 5px 0;
                }   
			}
			.popup-form-btn {
				border: none;
				outline: none;
				width: 100%;
				display: block;
				border-radius: 5px;
				padding: 20px 60px;
				color: $color_white !important;
				background: $color_primary;
				transition: all 0.25s linear;
				box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
				&:hover {
					color: $color_white !important;
					background:  darken($color_primary,5%);
					box-shadow: 0 10px 30px rgba($color_primary, 0.2);
				}
			}
			.custom-control {
				display: flex;
				align-items: center;
				min-height: auto;
				line-height: 26px;
				margin-bottom: 20px;
				.custom-control-label {
					margin-left: 15px;
					padding-left: 10px;
					color: $color_black;
					&:before {
						background: $color_white;
						border: 1px solid $color_primary;
						box-shadow: none !important;
					}
				}
				.custom-control-input {
					&:checked {
						& ~ .custom-control-label {
							&:before {
								color: $color_white;
								border-color: $color_primary;
								background: $color_primary;
							}
						}
					}
				}
			}
        }
    }
}

/* Comments Item */
.comment-item {
	margin-bottom: 30px;
	padding-bottom: 30px;
    border-bottom: 1px solid rgba($color_black, 0.08);
    &:last-child {
        border-bottom: 0;
    }
    .comment-item-title {
        margin-bottom: 10px;
    }
    img {
        border-radius: 5px;
        margin-right: 20px;
    }
    .comment-meta {
        margin-bottom: 10px;
        span {
            display: inline-block;
            margin-right: 10px;
            color: $color_black;
            font-size: 15px;
            i {
                margin-right: 10px;
                color: $color_primary;
            }
        }

       


    }
}

.reply-btn {
	display: inline-block;
	margin-top: 10px;
	font-weight: 500;
	color: #000 !important;
}
.reply-btn i {
	vertical-align: -1px;
	display: inline-block;
}

.sub-comment-item {
	margin-left: 120px;
}