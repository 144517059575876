.green-arrows{
position: relative;
	.owl-item {
		transform: scale(0.9, 0.9);
		// border-radius: 10px !important;
	}
    // mid
	&.owl-theme {

		.owl-nav {
@media screen and (max-width: 480px) {
  display: none;
  }

			button {
				top: 50%;
				height: 50px;
				width: 50px;
				font-size: 14px;
				position: absolute;
				display: inline-block;
				border-radius: 5px;
				color: $color_white !important;
				background: $color_secondary;
				transform: translateY(-50%);
				transition: all 0.25s linear;
				box-shadow: 0 10px 16px 0 rgba($color_secondary, 0.15);
				&:hover {
					color: $color_white !important;
					background:  darken($color_secondary,5%);
				}
				&.owl-next {
                    left: -60px;
                }
				&.owl-prev {
                    right: -60px;
				}
			}
		}
	}
}
