/* ---------------------------------------------------------------- */
/* 29.Home Page Particles                                 
/* ---------------------------------------------------------------- */
#heroparticles {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
