/* ---------------------------------------------------------------- */
/* 27.Home Page Parallax
/* ---------------------------------------------------------------- */
.bg-jarallax-overlay {
	position: relative;
    background: none;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background-position: center;
        background:#347b69;
        background-size: 200% auto;
    }
}
