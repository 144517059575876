/* ---------------------------------------------------------------- */
/* 30.Home Page Youtube Bg Video                                 
/* ---------------------------------------------------------------- */
.hero_video {
    width: 100%;
    position: relative;
    overflow: hidden;
    object-fit: scale-down;
    .hero-overlay {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 15;
        opacity: 0.8;
        position: absolute;
        background: $color_prestige_blue;
    }
    .video-content {
        z-index: 27;
        position: relative;
    }
    .video-scroll-btn {
        z-index: 32;
    }
}