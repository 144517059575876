/* ---------------------------------------------------------------- */
/* 07.Pagination                              
/* ---------------------------------------------------------------- */
.pagination-wrap {
    margin-top: 40px;
    .pagination-link {
        width: 42px;
        height: 42px;
        line-height: 42px;
        margin-right: 5px;
        border-radius: 5px;
        text-align: center;
        background: $color_white;
        display: inline-block;
        transition: all 0.25s linear;
        box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
        &:hover,&.active {
            color: $color_white;
            background: $color_primary;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}