/* ---------------------------------------------------------------- */
/* 03.Helper Class
/* ---------------------------------------------------------------- */
.section-padding {
	padding: 50px 0;
	position: relative;
}
.section-heading {
	text-align: center;
	margin-bottom: 60px;
	.section-title {
		text-align: center;
		position: relative;
		padding: 0 0 15px;
		margin-bottom: 15px;
		font-weight: 700;
		span {
			margin-right: 10px;
			margin-left: 10px;
			color: $color_primary;
		}
		&:after, &:before {
			content: "";
			left: 50%;
			height: 2px;
			position: absolute;
			background: $color_primary;
			transform: translateX(-50%);
		}
		&:after {
			bottom: -5px;
			width: 30px;
		}
		&:before {
			bottom: 0;
			width: 60px;
		}
	}
	.section-sub-title {
		text-align: center;
		font-size: 1rem;
	}
}
.section-badge {
	font-size: 14px;
	margin-bottom: 10px;
	border-radius: 5px;
	padding: 10px 20px;
	display: inline-block;
	color: $color_primary !important;
	background: rgba($color_primary, 0.1);
}
.bg-blue-overlay {
	&:after {
		top: 0;
		left: 0;
		z-index: -1;
		opacity: 0.8;
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		background-position: center;
		background: $color_primary;
	}
}
.item-shadow {
	transition: all 0.25s linear;
	box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
}
.item-shadow:hover {
    box-shadow: 0 15px 30px 0 rgba($color_primary,0.15);
}
.img-jump {
	animation: imgJump 5s linear 0s infinite normal forwards;
}
.pt-minus-70 {
	padding-top: 70px;
}
.pt-50 {
	padding-top: 50px;
}
.pb-minus-70 {
	padding-bottom: 70px;
}
