/* ---------------------------------------------------------------- */
/* 10.Hero Section
/* ---------------------------------------------------------------- */
.hero-section {
	position: relative;
	background-repeat: no-repeat;
    z-index: 42;
    height:70vh;
	background: $color_primary;
	display: flex;
	align-items: center;
    background: linear-gradient(to right, darken($color_primary, 5%), $color_primary);
    .hero-section-title {
        margin-bottom: 30px;
        line-height: 1.2;
        font-size: 40px;

        color: $color_white;
    }
    .hero-section-subtitle {
        opacity: 0.85;
        color:  $color_white;
        margin: 0 0 40px;
        line-height: 40px;
        font-size: 1.5rem;
    }
}
.down-scroll {
	left: 50%;
	width: 48px;
    height: 48px;
    z-index: 15;
    bottom: 25px;
    cursor: pointer;
    font-size: 16px;
    line-height: 48px;
    border-radius: 5px;
    position: absolute;
    text-align: center;
    color: $color_white !important;
    background: $color_secondary;
	transform: translateX(-50%);
	box-shadow: 0 10px 20px rgba($color_black, 0.1);
}
/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.hero-section, .hero-section-2, .parallax-bg {
		text-align: center;
		background-position: 100% 0;
    }
    .hero-section {
        min-height: 100vh;
        .hero-image {
            display: none;
        }
        .hero-section-subtitle {
            font-size: 1rem;
        }
    }
    .hero-section, .hero-section-2, .parallax-bg {
        .container {
            .row {
                justify-content: center !important;
            }
        }
    }
}
/* ------------------------------------------------------------- */
/* Responsive Media Query
 * Extra small devices (portrait phones, less than 576px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 575.98px) {
	.hero-section-title {
		font-size: 1.8rem !important;
	}
	section .button-group-hero {
		display: none !important;
	}
}
