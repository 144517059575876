/* ---------------------------------------------------------------- */
/* 04.Buttons
/* ---------------------------------------------------------------- */
.default-outline-btn {
	padding: 20px 40px;
	border-radius: 5px;
	display: inline-block;
	text-transform: capitalize;
	color: $color_white !important;
	background: $color_secondary;
	transition: all 0.25s linear;
	box-shadow: 0 10px 20px rgba($color_black, 0.1);
	&:hover {
		color: $color_white !important;
		background:  darken($color_secondary,5%);
		transform: translateY(-5px);
		box-shadow: 0 10px 30px rgba($color_black, 0.2);
	}
}
.default-btn {
	padding: 20px 40px;
	border-radius: 5px;
	display: inline-block;
	text-transform: capitalize;
	color: $color_white !important;
	background: $color_primary;
	transition: all 0.25s linear;
	box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
	&:hover {
		color: $color_white !important;
		background:  darken($color_primary,5%);
		transform: translateY(-5px);
		box-shadow: 0 10px 30px rgba($color_primary, 0.2);
	}
}
.default-video-btn {
	display: inline-block;
	padding: 20px 25px;
	border-radius: 5px;
	margin-left: 10px;
	text-align: center;
	text-transform: capitalize;
	color: $color_secondary !important;
	background: $color_white;
	transition: all 0.25s linear;
	box-shadow: 0 10px 20px rgba($color_black, 0.1);
	&:hover {
		color: $color_white !important;
		background: $color_secondary;
		transform: translateY(-5px);
		box-shadow: 0 10px 30px rgba($color_black, 0.2);
	}
}
.scroll-top-btn {
	bottom: 0;
	opacity: 0;
	right: 10px;
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 16px;
	text-align: center;
	position: fixed;
	border-radius: 5px;
	visibility: hidden;
	color: $color_white !important;
	background: $color_secondary;
	transition: all 0.25s ease;
	box-shadow: 0 10px 20px rgba($color_black, 0.1);
	&:hover {
		color: $color_white !important;
		background:  darken($color_secondary,5%);
		box-shadow: 0 10px 30px rgba($color_black, 0.2);
	}
	&.active {
		opacity: 1;
		bottom: 10px;
		visibility: visible;
	}
}
