/* ---------------------------------------------------------------- */
/* 02.Typography
/* ---------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font_heading;
	text-transform: capitalize;
	font-weight: 500;
	line-height: 1.5;
	color: $color_black;
	margin: 0;
}
h1 {
	font-size: 3rem;
}
h2 {
	font-size: 2.25rem;
}
h3 {
	font-size: 2rem;
}
h4 {
	font-size: 1.625rem;
}
h5 {
	font-size: 1.375rem;
}
h6 {
	font-size: 1.125rem !important;
}
p {
	margin: 0;
	line-height: 28px;
}
