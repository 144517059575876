/* ---------------------------------------------------------------- */
/* 08.Preloader                            
/* ---------------------------------------------------------------- */
.preloader-wrap {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 4444;
	top: 0;
	left: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #0033CC;
	flex-direction: column;
}
.preloader-wrap .preloader-inner {
	height: 100px;
	width: 100px;
	position: relative;
	margin: 0 auto;
}
 .preloader-inner .spinner {
	margin: auto;
	margin-top: -100px;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
}
.preloader-inner .spinner > div {
	background-color: #fff;
	height: 100%;
	width: 6px;
	display: inline-block;
	-webkit-animation: spinnerDelay 1.2s infinite ease-in-out;
	animation: spinnerDelay 1.2s infinite ease-in-out;
}
.preloader-inner .spinner .rect1 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.preloader-inner .spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.preloader-inner .spinner .rect3 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}
.preloader-inner .spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.preloader-inner .spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
.preloader-inner .sk-circle {
	margin: 10px auto 100px auto;
	width: 10px;
	height: 10px;
	position: relative;
	background: #fff;
	border-radius: 100%;
	left: -18px;
	-webkit-animation: spinnerLoad 1.2s infinite ease-in-out;
	animation: spinnerLoad 1.2s infinite ease-in-out;
}