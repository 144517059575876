// Fonts
$font_heading         : "Tajawal",sans-serif;
$font_text            : "Tajawal", sans-serif;

// Colors
$color_primary          : #454190;
$color_secondary        : #3EAF92;
$color_white            : #fff;
$color_orange           : #ff9900;
$color_dark_bg          : #141414;
$color_prestige_blue    : #2f3542;
$color_danger_bg        : #f8d7da;
$color_danger_text      : #721c24;
$color_footer           : #2f3542;
$color_black            : #000;
$body_text              : #636b72;
$color_light_grey       : #f2f2f2;
$color_primary_light    : rgba(0, 51, 204, 0.05);
$color_secondary_light  : rgba(19, 168, 116, 0.1);
$color_soft_grey        : #f5f5f5;
$color_grey_secondary   : #eee;
$color_shadow           : rgba(0, 51, 204, 0.1);
$color_shadow_hover     : rgba(0, 51, 204, 0.2);
