/* ---------------------------------------------------------------- */
/* 17.Testimonial       
/* ---------------------------------------------------------------- */
.testimonial-section {
    position: relative;
    background: $color_primary;
    .section-sub-title {
        opacity: 0.9;
    }    
    .section-title {
        &:after, &:before {
            background: $color_white;
        }
    }    
}
.testimonial-item {
	padding: 30px;
	position: relative;
    border-radius: 5px;
    background: $color_white;
    margin: 0 0 30px 0;
    display: flex;
    box-shadow: 0 2px 10px 0 rgba($color_black, 0.2);
    &:before {
        left: 50%;
        width: calc( 100% - 60px );
        height: 15px;
        content: '';
        z-index: -7;
        bottom: -15px;
        background: $color_white;
        position: absolute;
        box-shadow: 0 2px 10px 0 rgba($color_black, 0.2);
        transform: translateX(-50%);
        border-radius: 0 0 5px 5px;
    }
    .testimonial-img {
        flex: 0 0 250px;
        border-radius: 5px;
        margin-right: 30px;
        img {
            border-radius: 5px;
            width: inherit !important;
            display:inline-block !important;
        }
    }
    .testimonial-body {
        h5 {
            margin-bottom: 10px;
        }
        span {
            margin-bottom: 10px;
            display: block;
        }
        .testimonial-rating {
            margin-bottom: 30px;
            i {
                color: $color_orange;
                font-size: 20px;
            }
        }
        p {
            font-style: italic;
            font-size: 1.15rem;
            line-height: 2;
        }
    }
}









/* Owl Carousel */
.testimonial-section .testimonial-slider .owl-dots {
	margin-top: 60px !important;
}
.testimonial-section .owl-theme .owl-dot span {
    width: 10px;
    height: 8px;
    border-radius: inherit;
    border-color: #fff;
    background-size: cover;	
    background: #fff;
    opacity: 0.5;
    margin-right: 0;
    -webkit-transition: all 0.25s linear;
	transition: all 0.25s linear;
}
.testimonial-section .owl-theme .owl-dot.active span {
	width: 20px;
    opacity: 1 !important;
}

/* Testimonial Counters */
.counters {	
	padding-top: 100px;
}


.counter-item-inner {
	padding: 30px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	margin: 0 0 30px 0;
}

.counter-icon {
	position: relative;
	display: inline-block;
	font-size: 50px;
	width: 100px;
	height: 100px;
	line-height: 100px;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
    margin-bottom: 30px;
    color:  $color_secondary;
}
.counter-body h2 {
	font-size: 38px !important;
	line-height: 1;
	margin-bottom: 10px;
}

.counter-icon::after,
.counter-icon::before {
	content: "";
	display: block;
	position: absolute;
	border-radius: 50%;
	border:1px solid $color_secondary;
	left: -20px;
	right: -20px;
	top: -20px;
	bottom: -20px;
	opacity: 0;
	animation: iconPulse 1s linear infinite;
}
.counter-icon::after {
	animation-delay: 0.5s;
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Small devices (landscape phones, less than 768px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 767.98px) {
    .testimonial-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        .testimonial-img {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
}