/* ---------------------------------------------------------------- */
/* 18.Team
/* ---------------------------------------------------------------- */
.team-card {
    position: relative;
    border-radius: 5px;
    margin-bottom: 30px;
    text-align: center;
    background: $color_white;
    transition: all 0.25s linear;
	box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
    .team-img {
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -100%;
            left: 0;
            transition: all 0.35s ease-in-out 0s;
            background: $color_primary;
            opacity: 0.8;
        }
        img{
            height: 200px;
            max-height: 200px;
            width: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }
    .team-social {
        position: absolute;
        top: -100%;
        width: 100%;
        left: 0;
        height: 100%;
        z-index: 1;
        border-radius: 5px;
        display: none;
        li {
            display: inline-block;
            a {

                color: $color_white;
                border-radius: 5px;
                margin-right: 20px;
                display: inline-block;
                font-size: 30px;
                transition: all 0.3s ease 0s;
                &:hover {
                    color: $color_secondary;
                }
            }
            &:last-child {
                a {
                    margin-right: 0;
                }
            }
        }
    }
    &:hover {
        box-shadow: 0 15px 30px 0 rgba($color_primary,0.15);
        .team-img {
            &:after {
                bottom: 0;
            }
        }
        .team-social {
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: center;
            a {
                animation: fadeInUp 0.6s ease-in-out forwards;
            }
        }
    }
    .team-card-content{
        padding: 30px 0;
        .title {
            font-size: 20px;
            text-transform: capitalize;
            margin: 0 0 15px 0;
            position: relative;
        }
        .desc {
            display: inline-block;
            margin-bottom: 10px;
            border-radius: 5px;
            font-size: 14px;
            padding: 10px 20px;
            color: $color_white !important;
            background: $color_primary;
            text-transform: capitalize;
            box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
        }
    }
}
