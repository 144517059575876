/* ---------------------------------------------------------------- */
/* 23.Contact Us
/* ---------------------------------------------------------------- */
.form-control {
	color: $color_black;
    outline: none;
    border: none;
    height: auto;
    box-shadow: none;
	padding: 15px 20px;
    background: rgba($color_primary, 0.1);
    &:focus {
        color: $color_black;
        outline: none;
        border: none;
        box-shadow: none;
        background: rgba($color_primary, 0.1);
    }
}
.contact-form-group{
    margin-bottom: 30px;

}
textarea.form-control {
	height: auto;
    padding-top: 20px;
}
.contact-alert {
    .empty-form span, .success-form span, .email-invalid span {
        display: block;
        padding: 20px;
        margin-bottom: 10px;
    }
    .empty-form span, .email-invalid span {
        background: $color_danger_bg;
        color: $color_danger_text;
    }
}
.contant-info-wrap {
    .contact-info-item {
		border-radius:5px;
        padding: 30px;
        display: flex;
        margin-bottom: 30px;
		transition: all 0.25s linear;
		box-shadow: 0 23px 49px 0 rgba($color_primary,0.1);
		i {
			display: inline-block;
			margin-right: 20px;
			font-size: 35px;
			width: 80px;
			height: 80px;
			color: $color_white;
			flex: 0 0 80px;
			line-height: 80px;
			border-radius: 5px;
			text-align: center;
			background: $color_primary;
			box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
		}
		h6 {
			margin-bottom: 5px;
        }
        span {
            display: block;
        }
    }
}
.contact-social-links {
    border-radius:5px;
    padding: 30px 20px 20px 30px ;
    margin-bottom: 30px;
    transition: all 0.25s linear;
    box-shadow: 0 23px 49px 0 rgba($color_primary,0.1);
    ul {
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 10px;
            margin-bottom: 10px;
            a {
                i {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    color: $color_white;
                    font-size: 26px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background: $color_primary;
                    transition: all 0.25s linear;
                    box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
                }
                &:hover {
                    i {
                        color: $color_white !important;
                        background:  darken($color_primary,5%);
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
}
