/* ---------------------------------------------------------------- */
/* 31.Home Page Svg Pattern 01                             
/* ---------------------------------------------------------------- */
#svg-pattern-1 {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: auto;
	pointer-events: none;
	transform: rotate(180deg);
}