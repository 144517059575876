/* ---------------------------------------------------------------- */
/* 12.About    
/* ---------------------------------------------------------------- */
.about-col-left, .about-col-right {
    margin-bottom: 30px;
}
.about-col-right  {
    .about-list {
        margin: 0 0 40px 0;
        ul  {
            li {
                margin-top: 15px;
                font-weight: 500;
                color: #111;
                &:first-child {
                    margin-top: 0;
                }
                i {
                    margin-right: 15px;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    display: inline-block;
                    border-radius: 5px;
                    font-size: 10px;
                    color: #fff !important;
                    background: $color_primary;
                    box-shadow: 0 10px 16px 0 rgba(0, 51, 204, 0.1);
                }
            }
        }
    }
    .about-inner {
        h3 {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 30px;
        }
    }   
}
/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Small devices (landscape phones, less than 768px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 767.98px) {
	.about-inner {
		margin-top: 50px;
	}
}
