/* ---------------------------------------------------------------- */
/* 09.Header
/* ---------------------------------------------------------------- */
.header {
    padding: 10px 0;
    z-index: 3333;
    .nav-link {
        font-size: 16px;
        font-weight: 600;
        padding: 20px 15px !important;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        display: block;
        position: relative;
        color: $color_white;
        line-height: 28px;
        &.active {
            &:after {
                left: 0;
                bottom: 0;
                width: 100%;
                height: 7px;
                content: "";
                position: absolute;
                background: $color_secondary;
            }
        }
        &:not(.active) {
            &:after {
                left: 0;
                bottom: 0;
                width: 100%;
                height: 7px;
                z-index: -7;
                content: "";
                position: absolute;
                background: $color_secondary;
                transition: transform 0.4s ease;
                transform-origin: 100% 0;
                transform: scaleX(0);
            }
            &:hover {
                &:after {
                    transform: scaleX(1);
                    transform-origin: 0 0;
                }
            }
        }
    }
    .dropdown {
        &:hover {
            .nav-link {
                &:after {
                    transform: scaleX(1);
                    transform-origin: 0 0;
                }
            }
        }
    }
    .dropdown-toggle {
        padding-right: 25px !important;
        &:before {
            top: 50%;
            right: 0;
            content: "\f078";
            font-weight: 700;
            font-size: 12px;
            position: absolute;
            display: inline-block;
            font-family: "Font Awesome 5 Free";
            transition: all 0.25s ease;
            transform: translateY(-50%);
        }
        &[aria-expanded="true"] {
            &:before {
                transform: rotate(180deg);
                top: 20px;
            }
        }
        &:after {
            content: "";
            border: none;
            margin-left: 0;
            display: inline-block;
            vertical-align: initial;
        }
    }

    .dropdown-submenu {
        position: relative;

        .has-child{
            &:after {
                font-weight: 700;
                font-size: 12px;
                content: "\f078";
                font-family: "Font Awesome 5 Free";
                float: right;
                transform: rotate(-90deg);
                position: absolute;
                right: 6px;
                top: 30%;
            }
        }
    //    &:hover>.dropdown-menu {
    //         display: block;
    //     }
        .dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: 0px;
            margin-left: 0px;
        }
        .dropdown-submenu:hover>.dropdown-menu {
            display: block;
        }
        @media (max-width: 980px) {

            &.dropdown-menu {
                left:0;
                display: none;

            }
            &:hover>.dropdown-menu {
                display: none !important;

            }
            &.dropdown-submenu .show {
                display: block !important;

            }
            .has-child{
                &:after {

                    font-family: "Font Awesome 5 Free";
                    float: right;
                    transform: rotate(0deg);

                }
            }
          }
        &:hover>.dropdown-menu {
            display: block;
            opacity: 1;
            top: 20%;
            visibility: visible;
        }
        // .dropdown-toggle {
        //     &[aria-expanded="true"] {
        //         &:before {
        //             transform: rotate(-90deg);
        //             position: absolute;
        //             right: 6px;
        //             top: 0.8em;
        //         }
        //     }
        // }
    }

    .dropdown-menu {
        border: 0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        background: $color_white;
        box-shadow: 0 13px 35px 0 $color_shadow;
        .dropdown-item {
            // width: 100%;
            color: $color_black;
            display: block;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            padding: 12px 24px;
            text-decoration: none;
            text-align: right;
            transition: all 0.25s ease;
            &:focus,
            &:hover {
                color: $color_white;
                background: $color_secondary;
                padding-left: 25px;
            }
        }
    }
    .navbar-toggler {
        outline: none;
        border: none;
        border-radius: 0;
        padding: 0 10px 0 0;
        .togler-icon-inner {
            span {
                height: 3px;
                margin: 5px;
                width: 30px;
                display: block;
                background: $color_white;
                transition: all 0.3s ease;
                &:nth-child(2) {
                    margin-left: auto;
                    width: 20px !important;
                }
                &:nth-child(3) {
                    margin-bottom: 0;
                }
            }
        }
        &[aria-expanded="true"] {
            .togler-icon-inner {
                span {
                    &:nth-child(1) {
                        transform: rotate(-45deg) translate(-5px, 6px);
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        transform: rotate(45deg) translate(-5px, -6px);
                    }
                }
            }
        }
    }
    &.header-shrink {
        padding: 0;
        background: $color_white;
        box-shadow: 0 13px 35px 0 $color_shadow;
        animation: stickyMenu 0.7s ease-in-out;
        .nav-link {
            color: $color_black;
        }
        .togler-icon-inner {
            span {
                background: $color_black;
            }
        }
    }
    .login-btn-mobile,
    .signup-btn-mobile {
        display: none;
    }
    .navbar-brand {
        margin-right: 0;
        padding: 0;
        img {
            display: none;
            &.logo-transparent {
                display: block;
            }
        }
    }
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media screen and (max-width: 991.98px) {
    .header {
        padding: 0;
        .container,
        ul li {
            padding: 0;
            max-width: 100%;
        }
        .navbar-brand {
            padding: 10px 0 10px 15px;
            img{
                width: 210px ;
            }
        }
        .main-menu {
            background: $color_white;
            .navbar-nav {
                max-height: 400px;
                overflow: hidden;
                overflow-y: auto;
                .nav-item {
                    .nav-link {
                        color: $color_black;
                        padding: 10px 15px !important;
                        border-bottom: 1px solid $color_light_grey;
                        transition: all 0.25s ease;
                        &:after {
                            display: none;
                        }
                        &:not(.active) {
                            &:hover {
                                background: $color_secondary;
                                color: $color_white !important;
                                border-color: $color_secondary;
                            }
                        }
                        &.active {
                            background: $color_secondary;
                            color: $color_white !important;
                            border-color: $color_secondary;
                        }
                        &.dropdown-toggle {
                            &::before {
                                top: 27px;
                                right: 20px;
                            }
                            &[aria-expanded="true"] {
                                &:before {
                                    top: 11px;
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                    &:last-child {
                        .nav-link {
                            border-bottom: 0;
                        }
                    }
                    .dropdown-menu {
                        box-shadow: none;
                        background: rgba($color_secondary, 0.1);
                        .dropdown-item {
                            &:hover {
                                background: $color_secondary;
                            }
                        }
                    }
                }
            }
        }
        .login-btn-mobile,
        .signup-btn-mobile {
            top: 32px;
            border: none;
            color: $color_white;
            font-size: 16px;
            display: block;
            position: absolute;
            background: transparent;
        }
        .signup-btn-mobile {
            right: 65px;
        }
        .login-btn-mobile {
            right: 110px;
        }
        .login-btn-resp,
        .signup-btn-resp {
            display: none;
        }
        &.header-shrink {
            .login-btn-mobile,
            .signup-btn-mobile {
                color: $color_black;
            }
        }
    }
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Large devices (desktop, less than min-width: 992px)
/* ---------------------------------------------------------------- */
@media screen and (min-width: 992px) {
    .dropdown-menu {
        left: 0;
        border: 0;
        top: 80%;
        z-index: 15;
        opacity: 0;
        color: $color_black;
        visibility: hidden;
        display: initial;
        // min-width: 250px;
        border-radius: 0;
        position: absolute;
        transition: all 0.5s ease;
        &.show {
            opacity: 1;
            top: 100%;
            visibility: visible;
        }
    }
}
