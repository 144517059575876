/* ---------------------------------------------------------------- */
/* 20.Latest Blog
/* ---------------------------------------------------------------- */
.blog-item {
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;
    min-height: 485px;
    background: $color_white;
    transition: all 0.25s linear;
    box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
    &:hover {
        box-shadow: 0 15px 30px 0 rgba($color_primary,0.15);
    }
    .blog-item-inner {
        padding: 30px;

    // min-height: 300px;
    // height: 300px;
        .blog-link {
            font-size: 14px;
            color: $color_white !important;
            padding: 10px 20px;
            border-radius: 5px;
            display: inline-block;
            background: $color_secondary;
            transition: all 0.25s linear;
            box-shadow: 0 5px 20px 0 rgba($color_secondary, 0.2);
            &:hover {
                color: $color_white !important;
                background:  darken($color_secondary,5%);
                transform: translateY(-5px);
            }
        }
        .blog-body {
            a {
                &:hover {
                    h6 {
                        color: $color_primary;
                    }
                }
               h6 {
                    margin-bottom: 10px;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical
               }
            }
            p {
                margin-bottom: 20px;
                display: block;
                -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                min-height: 85px;
                -webkit-box-orient: vertical
            }
        }
        .blog-meta {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            a {
                font-size: 15px;
                margin-bottom: 0;
                margin-left: 10px;
                transition: all 0.25s linear;
                &:hover {
                    color: $color_primary;
                }
                i {
                    color: $color_primary;
                    margin-right: 5px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .blog-date {
        position: absolute;
        top: -10px;
        left: 30px;
        text-align: center;
        background: $color_primary;
        border-radius: 0 5px 5px 5px;
        min-width: 80px;
        &::after {
            top: 6px;
            left: -14px;
            z-index: -22;
            content: "";
            position: absolute;
            border: 15px solid;
            transform: rotate(45deg);
            border-color: transparent transparent transparent $color_primary;
        }
        .blog-day {
            padding: 10px 15px;
            display: block;
            font-size: 20px;
            color: $color_white;
            font-weight: 500;
            font-family: $font_heading;
        }
        .blog-month {
            padding: 10px 20px;
            display: block;
            font-size: 14px;
            color: $color_white;
            background: rgba($color_white,0.1);
            border-radius: 0 0 5px 5px;
        }
    }

    .blog-img {
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
        img {
            border-radius: 5px;
            transition: all 0.5s ease;
            height: 230px;
            max-height: 230px;
            max-width: 100%;
            // min-width: 100%;
            object-fit: cover;
        }
    }
}


.blog-description__container{
    img{
        max-width: 100%;

    }
}
