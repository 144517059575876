/* ---------------------------------------------------------------- */
/* 11.Services
/* ---------------------------------------------------------------- */
.services-item {
    padding: 30px;
    position: relative;
	background: $color_white;
    border-radius: 10px;
    margin-bottom: 30px;
    .service-icon-container{
        width: 100%;
        display: flex;
        justify-content: center;
    .services-icon {
        height: 80px;
        width: 80px;
        color: $color_white;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        line-height: 80px;
        border-radius:5px;
        background: $color_primary;
        box-shadow: 0 5px 20px 0 rgba($color_primary, 0.3);
    }
    }
    .service-image{
        max-width: 40px;
        width: 40px;
        max-height: 40px;
        height: 40px;
        object-fit: contain;
    }
    .services-link {
        font-size: 14px;
        color: $color_white !important;
        padding: 10px 20px;
        border-radius: 5px;
		display: inline-block;
		background: $color_secondary;
        transition: all 0.25s linear;
        box-shadow: 0 5px 20px 0 rgba($color_secondary, 0.2);
        &:hover {
            color: $color_white !important;
            background:  darken($color_secondary,5%);
            transform: translateY(-5px);
        }
    }
    p{
        margin-bottom: 20px;
        display: block;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 3;
        overflow: hidden;
        min-height: 54px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical
    }
    .services-body {
        h5 {
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 20px;
        }
    }
}
.services-page {
    .services-item {
        .services-body {
            p {
                margin-bottom: 0;
            }
        }
    }
}
