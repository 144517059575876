/* ---------------------------------------------------------------- */
/* 22.Faq      
/* ---------------------------------------------------------------- */
.faq {
	overflow: visible;
}
.faq-title {
	margin-bottom: 60px;
}
#faq-tab-accordion .card-header {
	padding: 15px;
    background: #fff;
    border:none;
	cursor: pointer;
	margin-bottom: 10px;
	position: relative;
	border-radius: 5px;
	border: none;
	-webkit-transition: all 0.25s linear;
	transition: all 0.25s linear;
	background-size: 200% auto;
	-webkit-box-shadow: 0 10px 30px 0 rgba(0, 51, 204,0.1);
	box-shadow: 0 10px 20px 0 rgba(0, 51, 204,0.1);
}
#faq-tab-accordion .card {
	border: 0;
}
#faq-tab-accordion .card-header a {
	color: #000;
	font-weight: 600;
}
.card-header h6 {
	font-size: 1.125rem;
}
.card-header h6 span {
	height: 50px;
    width: 50px;
    margin-right: 10px;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
	background: rgba(74, 79, 230, 0.1);
	color: #0033CC;
	font-size: 1.125rem;
}
#faq-tab-accordion .card-toggle-btn::after {
	content: "\f067";
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
	height: 40px;
    width: 40px;
    margin-left: 10px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
	background: rgba(74, 79, 230, 0.1);
	color: #0033CC;
	transition: all 0.25s linear;
}
#faq-tab-accordion .card-header[aria-expanded="true"] .card-toggle-btn::after {
	transform: rotate(180deg);
	content: "\f068";
}
#faq-tab-accordion .card-header[aria-expanded="true"] {
	background: #0033CC;
	border-bottom-color: transparent;
}
#faq-tab-accordion .card-header[aria-expanded="true"] .card-toggle-btn::after,
#faq-tab-accordion .card-header[aria-expanded="true"] h6 span {
	background: #fff;
}
#faq-tab-accordion .card-header[aria-expanded="true"] h6 {
	color: #fff;
}

/* FAQ Form */
.faq-form-wrap {
	padding: 0 15px;
}
.faq-form-title {
	margin-bottom: 30px;
}
.faq-form-control {
	margin-bottom: 30px;
	color: #100e17;
	outline: none;
	padding: 18px 20px;
	font-size: 0.9rem;
	border: none;
	width: 100%;
	border-radius: 5px;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
	-webkit-box-shadow: 0 10px 30px 0 rgba(0, 51, 204,0.1);
	box-shadow: 0 10px 20px 0 rgba(0, 51, 204,0.1);
}
.faq-form-control:focus {
	-webkit-box-shadow: 0 10px 26px 0 rgba(0, 51, 204, 0.15);
	box-shadow: 0 10px 26px 0 rgba(0, 51, 204, 0.15);
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.faq-form-margin {
		margin-top: 50px;
	}
}
