/* ---------------------------------------------------------------- */
/* 37.Blog Single
/* ---------------------------------------------------------------- */
.blog-single {
	img {
		border-radius: 5px;
	}
	.blog-post-desc {
		> p {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 30px;
			}
		}
		.blog-single-meta {
			padding: 20px 0;
			margin-bottom: 15px;
			border-bottom: 1px solid rgba($color_black, 0.06);
			.blog-single-meta-box {
				span {
					margin-right: 10px;
					&:last-child {
						margin-right: 0;
					}
					i {
						color: $color_primary;
						margin-right: 10px;
						width: 36px;
						height: 36px;
						line-height: 36px;
						display: inline-block;
						background: rgba($color_primary,0.1);
						text-align: center;
						border-radius: 50%;
					}
				}
				a {
					display: inline-block;
					width: 36px;
					height: 36px;
					line-height: 36px;
					border-radius: 50%;
					background: rgba($color_primary,0.1);
					color: $color_primary;
					text-align: center;
					margin-right: 5px;
					&:hover {
						background: $color_primary;
						color: $color_white !important;
					}
				}
			}
		}
		.blog-title-h2 {
			margin-bottom: 15px;
		}
	}
	.blog-post-quote {
		blockquote {
			padding: 30px;
			margin-bottom: 30px;
			border-radius: 5px;
			border-left: 5px solid $color_primary;
			box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
			p {
				font-style: italic;
				font-size: 1.125rem;
				strong {
					margin-top: 10px;
					display: block;
					color: $color_black;
				}
			}
		}
	}
	.blog-subheadline {
		margin: 30px 0;
		span {
			margin-right: 15px;
		}
	}
	.blog-title-subline {
		margin-bottom: 20px;
	}
}
.blog-post-sidebar {
	.blog-widgets {
		margin-bottom: 30px;
		.blog-search-form {
			width: 100%;
			border: none;
			color: $color_black;
		}
		.blog-search-btn {
			top: 0;
			right: 0;
			border: none;
			width: 60px;
			outline: none;
			height: 100%;
			color: $color_white;
			position: absolute;
			background: $color_primary;
			border-radius: 0 5px 5px 0;
		}
		.recent-post-item {
			margin-bottom: 14px;
			.recent-post-img {
				float: left;
                max-width:85px;
                max-height:85px;
                height:85px;
                width:85px;
                object-fit: cover;
			}
			.recent-post-title {
				font-size: 1rem;
				margin-bottom: 8px;
				line-height: 1.5;
				&:hover {
					color: $color_primary;
				}
			}
			.recent-post-details {
				a {
					color: $color_primary;
				}
			}
			.recent-post-date {
				font-size: 15px;
				i {
					margin-right: 8px;
					color: $color_primary;
				}
			}
		}
		.blog-category-list {
			li {
				padding: 12px 0;
				border-bottom: 1px solid rgba($color_black,0.08);
				&:hover {
					a {
						color: $color_primary;
						text-decoration: underline;
					}
				}
				a {
					.category-count {
						float: right;
					}
				}
			}
		}
		.blog-tags {
			li {
				float: left;
				&.active {
					a {
						background: $color_primary;
						color: $color_white;
					}
				}
				a {
					color: $color_black;
					margin: 3px;
					padding: 10px;
					font-size: 14px;
					border-radius: 5px;
					display: inline-block;
					transition: all 0.25s linear;
					box-shadow: 0 10px 20px 0 rgba($color_primary,0.1);
					&:hover {
						color: $color_white;
						background: $color_primary;
					}
				}
			}
		}
	}
}
.blog-title {
	position: relative;
	&:after {
		z-index: 1;
		content: "";
		width: 50px;
		display: block;
		position: relative;
		margin: 20px auto 20px 0;
		border-bottom: 2px solid $color_primary;
	}
	&:before {
		border-bottom: 2px solid rgba(0, 0, 0, 0.06);
		bottom: 0;
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		border-bottom: 2px solid rgba($color_black, 0.06);
	}
}
.blog-title-margin {
	margin-bottom: 30px;
}
/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.blog-sidebar-wrap {
		margin-top: 50px;
	}
}
/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Extra small devices  (portrait phones, less than 576px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 575.98px) {
	.blog-single-meta {
		flex-direction: column;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
	.blog-single-meta .blog-single-meta-box > span {
		display: block;
		margin-bottom: 10px;
	}
	.sub-comment-item {
		margin-left: 0;
	}
}
.blog-search-form {
    width: 100%;
    border: none;
    color: $color_black;
}
.blog-search-btn {
    top: 0;
    left: 0;
    border: none;
    width: 60px;
    outline: none;
    height: 100%;
    color: $color_white;
    position: absolute;
    background: $color_primary;
    border-radius: 0 5px 5px 0;
}
