/* ---------------------------------------------------------------- */
/* 15.Screenshots
/* ---------------------------------------------------------------- */
.screenshots-slider {
	position: relative;
	.owl-item {
		opacity: 0.7;
		transition: all 0.5s ease;
		transform: scale(0.7, 0.7);
		border-radius: 10px !important;
		img {
			border-radius: 10px !important;
		}
	}
	.active {
		&.center {
			opacity: 1;
			transform: scale(1);
		}
	}
	&.owl-theme {

		.owl-nav {
			button {
				top: 50%;
				height: 50px;
				width: 50px;
				font-size: 14px;
				position: absolute;
				display: inline-block;
				border-radius: 5px;
				color: $color_white !important;
				background: $color_secondary;
				transform: translateY(-50%);
				transition: all 0.25s linear;
				box-shadow: 0 10px 16px 0 rgba($color_secondary, 0.15);
				&:hover {
					color: $color_white !important;
					background:  darken($color_secondary,5%);
				}
				&.owl-next {
					right: -30px;
				}
				&.owl-prev {
					left: -30px;
				}
			}
		}
	}
}


/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Small devices (landscape phones, less than 768px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 767.98px) {
	.screenshots-slider {
		position: relative;
		.owl-item {
			opacity: 0.6;
			transition: all 0.5s ease;
			transform: scale(0.8, 0.8);
			border-radius: 10px !important;
			img {
				border-radius: 10px !important;
			}
		}
		&.owl-theme {
			.owl-nav {
				button {
					&.owl-next {
						right: 15px;
					}
					&.owl-prev {
						left: 15px;
					}
				}
			}
		}
	}
}
