
/* ---------------------------------------------------------------- */
/* 19.Download                           
/* ---------------------------------------------------------------- */
.download-wrap {
    background: $color_primary;
}
.download-section-inner {
    h2 {
        color: $color_white;
        margin-bottom: 15px;
    }
    p {
        opacity: 0.9;
        color: $color_white;
        margin-bottom: 20px;
    }

}



.download-buttons {
    margin-top: 40px;
    
    .download-btn {
        display: inline-block;
        overflow: hidden;
        padding: 20px 30px;
        max-width: 200px;
        border-radius: 5px;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 500;
        position: relative;
        text-align: left;
        margin-right: 15px;
        background: transparent;
        text-transform: uppercase;
        color: $color_white !important;
        transition: all 0.25s linear;
        box-shadow: 0 10px 20px rgba($color_black, 0.15);
        &:hover {
            background: $color_white;
	        color: $color_primary !important;
        }
        &:last-child {
            margin-right: 0;
        }
        i {
            top: 50%;
            left: 20px;
            font-size: 30px;
            position: absolute;
            display: inline-block;
            transform: translateY(-50%);
        }
        span {
            display: block;
            margin-left: 35px;
        }
    }
    
}
.download-stars {
    position: relative;
    .download-ratings {
        display: inline-block;
        position: relative;
        padding: 10px 20px 10px 0;
        i {
            color: #fdd55e;
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
        span {
            color: #fff;
            font-weight: 600;
            font-size: 3rem;
            margin-right: 10px;
        }
    }
    .download-rate-count {
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        color: #000;
        position: relative;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        &:before {
            top: 7px;
            left: -28px;
            content: "";
            border: 15px solid;
            border-radius: 5px;
            position: absolute;
            border-color: transparent $color_white transparent transparent;
        }
    }
}




/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.download-section-inner {
		text-align: center;
	}
	.download-image {
		display: none;
	}
	.download-section-inner .download-buttons,
	.download-stars {
		justify-content: center !important;
	}
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Extra small devices (portrait phones, less than 576px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 575.98px) {
	.download-section-inner .download-buttons {
		text-align: center;
		display: block !important;
	}
	.download-section-inner .download-buttons .download-btn {
		display: block;
		margin: 0 auto 15px auto;
	}
	.download-section-inner .download-buttons .download-btn:nth-child(2) {
		margin-bottom: 0;
	}
}