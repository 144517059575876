/* ---------------------------------------------------------------- */
/* 16.Pricing                              
/* ---------------------------------------------------------------- */
.price-toggle-wrap {
	text-align: center;
	margin-bottom: 60px;
	display: inline-block;
	margin: 0 auto 60px auto;
	padding: 10px;
	border-radius: 5px;
	-webkit-box-shadow: 0 10px 30px 0 rgba(0, 51, 204,0.1);
	box-shadow: 0 10px 20px 0 rgba(0, 51, 204,0.1);
}
.pricing-tab-content {
	display: none;
}
.pricing-tab-content.active {
	display: block;
}
.price-toggle-wrap > a {
	padding: 20px 50px;
	float: left;
	border-radius: 5px;
	margin-right: 10px;
	background-position: center;
	position: relative;
	overflow: hidden;
	background-color: #e8eafd;
    color: #0033CC;
}
.price-toggle-wrap > a.active {
	background-position: center;
	background: #0033CC;
	color: #fff;
}

/* Price Table */
.price-table {
	border-radius: 5px;
	padding: 40px 0;
	background: #fff;
}
.price-table-active .price-table {
	background: #0033CC;
	-webkit-box-shadow: 0 10px 16px 0 rgba(0, 51, 204, 0.15);
	box-shadow: 0 10px 16px 0 rgba(0, 51, 204, 0.15);
}
.price-table-active .price-icon span  {
    background: #fff;
	color: #0033CC;
}
.price-table-active h4, .price-table-active li,
.price-table-active .price-body > ul > li::before,
.price-table-active .price-table h5 {
	color: #fff !important;
}
.price-table .price-header, .price-body, .price-btn-wrap {
	padding: 0 30px;
}
.price-btn-wrap {
	text-align: center;
}
.price-table .price-header h4 {
	font-size: 1.4rem;
	text-transform: capitalize;
	margin-bottom: 7px;
}
.price-icon span {
	height: 80px;
	width: 80px;
	text-align: center;
	display: block;
	font-size: 40px;
	border-radius: 5px;
	line-height: 80px;
	background: #0033CC;
	color: #fff;
	-webkit-box-shadow: 0 10px 16px 0 rgba(0, 51, 204, 0.15);
	box-shadow: 0 10px 16px 0 rgba(0, 51, 204, 0.15);
}
.price-table .price-value h5 {
	font-size: 2rem;
}
.price-table h5 > sub {
	
	bottom: 0;
}
.price-body {
	border-top: 1px solid rgba(0, 0, 0, 0.062);
	margin: 40px 0;
	padding-top: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.062);
}
.price-table-active .price-body {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.price-table-active .price-table .price-body > ul > li::after {
	color: #fff;
}
.price-table .price-body > ul {
	text-align: left;
}
.price-table .price-body > ul > li {
	margin-bottom: 15px;
}
.price-table .price-body > ul > li::before {
	content: "\f0da";
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
	margin-right: 15px;
	color: #15db95;
}
.price-table .price-body > ul > li::after {
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-weight: 600;
	color: #15db95;
	float: right;
}
.price-table .price-body > ul > li:last-child {
	margin-bottom: 0;
}
.price-table .default-btn {
	display: block;
}

.price-table-active .default-btn {

	padding: 18px 40px;
	background: transparent;
	border:2px solid $color_white;
	&:hover {
		background: #fff;
		color: #0033CC !important;
	}
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.price-table-margin {
		margin-bottom: 50px;
	}
}
/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Extra small devices (portrait phones, Iphone old version (5) 320px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 320px) {
	.price-toggle-wrap > a {
		padding: 20px 30px;
	}
}
