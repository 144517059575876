/* ---------------------------------------------------------------- */
/* 14.How it Works                            
/* ---------------------------------------------------------------- */
.how-it-works-section {
    position: relative;	
    background: $color_primary;
    .section-sub-title {
        opacity: 0.9;
        color: $color_white;
    }    
    .section-title {
        color: $color_white;
        span {
            color: $color_white;   
        }
        &:after, &:before {
            background: $color_white;
        }
    }   
}

.how-it-works-inner {
    h3 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 40px;
        opacity: 0.85;
    }
}
.how-it-work-video {
    position: relative;
    .how-it-works-video-btn {
        position: absolute;
        text-align: center;
        height: 80px;
        width: 80px;
        line-height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        cursor: pointer;
        font-size: 40px;
        background: $color_secondary;
        color: #fff !important;
        -webkit-animation: shadowPulse 2s linear infinite;
        animation: shadowPulse 2s linear infinite;
        box-shadow: 0 0 0 10px rgba(21, 219, 150, 0.4);
    }
}