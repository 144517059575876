/* ---------------------------------------------------------------- */
/* 33.Home Page Svg Pattern 03                             
/* ---------------------------------------------------------------- */
#svg-pattern-3 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	pointer-events: none;
}