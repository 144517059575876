/* ---------------------------------------------------------------- */
/* 21.Newsletter      
/* ---------------------------------------------------------------- */
.newsletter {
	background: $color_primary;
}
/* Newsletter Form */
.newsletter-wrap {
	.newsletter-title {
		margin-bottom: 60px;
	}	
	.newsletter-form-group {
		position: relative;
		overflow: hidden;
		.newsletter-form-control {
			width: 100%;
			height: 80px;
			border:none;
			display: block;
			border-radius: 5px;
			padding: 0 80px 0 55px;
		}
		.newsletter-form-btn {
			top: 50%;
			right: 10px;
			width: 60px;
			height: 60px;
			color: $color_white;
			border-radius: 5px;
			position: absolute;
			background: $color_secondary;
			transition: all 0.25s linear;
			&:hover {
				color: $color_white !important;
				background:  darken($color_secondary,5%);
			}
		}
		span, .newsletter-form-btn {
			transform: translateY(-50%);
		}
		span {
			top: 50%;
			left: 18.5px;
			opacity: 0.8;
			font-size: 18px;
			position: absolute;
		}
	}
}