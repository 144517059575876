/* ---------------------------------------------------------------- */
/* 24.Footer
/* ---------------------------------------------------------------- */
.footer {
	background: $color_footer;
	.footer-top {
		padding: 35px 0 50px 0;
		.footer-title {
			font-weight: 600;
			color: $color_white;
			margin-bottom: 30px;
			&:after {
				content: "";
				width: 50px;
				height: 2px;
				background: $color_white;
				display: block;
				margin-top: 15px;
			}
		}
	}

    @media screen and (max-width:900px){

        .footer-logo{
            width: 100% !important;
        }
    }
    .footer-logo{
        width: 35%;
    }
	li {
		margin-top: 10px;
		color: $color_white;
		&:first-child {
			margin-top: 0;
		}
		a {
			opacity: 0.7;
			color: $color_white;
		}
	}
	.footer-links {
		li {
			&:before {
				color: $color_secondary;
				font-weight: 600;
				content: "\f101";
				margin-left: 10px;
				vertical-align: top;
                transform: rotate(180deg);
				display: inline-block;
				font-family: "Font Awesome 5 Free";
			}
		}
	}
}
.footer-widget {
	margin-bottom: 30px;
	.footer-box {
		.contact-info-footer {
			margin-top: 10px;
			li {
				color: rgba($color_white, 0.7);
				i {
					margin-right: 15px;
					color: $color_secondary;
				}
			}
		}
		.footer-links {
			li {
				&:hover {
					opacity: 1;
					a {
						text-decoration: underline;
						opacity: 1;
					}
				}
			}
		}
		p {
			color: $color_white;
			opacity: 0.7;
			i {
				color: $color_white;
			}
		}
		.footer-social-links {
			margin-top: 10px;
			a {
				height: 42px;
				width: 42px;
				color: $color_white;
				margin-right: 10px;
				line-height: 42px;
				text-align: center;
				border-radius: 50%;
				display: inline-block;
				transition: all 0.25s ease;
				background: rgba($color_white, 0.05);
				&:last-child {
					margin-right: 0;
				}
				&:hover {
					background: $color_secondary;
				}
			}
		}
	}
}
.copyright {
	opacity: 0.8;
	color: $color_white;
	padding: 25px 0;
	background: rgba($color_white, 0.05);
}
