/* ---------------------------------------------------------------- */
/* 13.Features                                 
/* ---------------------------------------------------------------- */
.features-list{
	display: inline-block;
	li {
		margin-top: 30px;
		border-radius:5px;
		padding: 30px;
		transition: all 0.25s linear;
		box-shadow: 0 23px 49px 0 rgba($color_primary,0.1);
		&:first-child {
			margin-top: 0;
		}
		i {
			display: inline-block;
			margin-right: 20px;
			font-size: 35px;
			width: 80px;
			height: 80px;
			color: $color_white;
			flex: 0 0 80px;
			line-height: 80px;
			border-radius: 5px;
			text-align: center;
			background: $color_primary;
			box-shadow: 0 10px 16px 0 rgba($color_primary, 0.15);
		}
		h6 {
			margin-bottom: 5px;
		}
	}
}
.features-inner {
	h3 {
		margin-bottom: 15px;
	}
	p {
		margin-bottom: 30px;
	}
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Medium devices (tablets, less than 992px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 991.98px) {
	.features-image-mb {
		margin-bottom: 30px;
	}
	.features-image-order {
		order:2;
	}
}
.features-row-mt {
	margin-top: 100px;
}

/* ---------------------------------------------------------------- */
/* Responsive Media Query
 * Small devices (landscape phones, less than 768px)
/* ---------------------------------------------------------------- */
@media only screen and (max-width: 767.98px) {
	.features-image-resp {
		margin-top: 60px;
	}
	.features-row-mt {
		margin-top: 60px;
	}
}
