/* ---------------------------------------------------*/
/* 36.Home Page Intro
/* ---------------------------------------------------*/
.hero-section {
    .intro-hero-subtitle {
        color: $color_white;
        opacity: 0.9;
        font-weight: 300;
        font-size: 1.2em;
    }
    .intro-hero-title {
        color: $color_white;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 20px;
        span {
            font-size: 3.5rem;
            font-weight: 700;
            display: block;
            margin-bottom: 10px;
            max-width: max-content;
            padding: 10px 20px;
            text-transform: uppercase;
            margin: 0 auto 15px auto;
            border: 2px solid $color_white,
        }
    }
}
/* Demo Item */
.demo-item {
    margin-bottom: 30px;
    position: relative;
    border-radius: 10px;
    transition: all 0.4s ease;
    box-shadow: 0 23px 49px 0 rgba($color_primary, 0.15);
    .demo-title {
        padding:30px 0;
        display: block;
        text-align: center;
        color: $color_black;
        font-size: 1.2rem;
        font-weight: 600;
        background: $color_white;
        text-transform: capitalize;
        border-radius: 0 0 10px 10px;
    }
    .demo-img {
        overflow: hidden;
        position: relative;
        border-radius: 10px 10px 0 0;
    }
    .demo-item-link {
        padding: 30px;
        display: block;
        font-weight: 600;
        font-size: 1.125rem;
        background: $color_white;
        border-radius: 0 0 5px 5px;
        color: $color_black !important;
        transition: all 0.25s ease;
    
    }
    &:hover {
        .demo-item-link {
            color: $color_white !important;
            background: $color_primary;
        }
    }
}
/* ------------------------------------------------------------- */
/* Responsive Media Query
 * Extra small devices (portrait phones, less than 576px)
/* ---------------------------------------------------------------- */
@media screen and (max-width:576px){
    .hero-section {
        .intro-hero-title {
            font-size: 1.6rem;
            span {
                font-size: 2.5rem;
            }
        }
    }
}