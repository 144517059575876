#breadcrumbs {
    background-color: transparent;
}
.alert.header-message {
    border-radius: 0 !important;
}

body.rtl {
    text-align: right;
}


body.rtl  .form-check {
    padding-right: 1.25rem;
    padding-left: 0;

}

body.rtl  .form-check-input {
    margin-right: -1.25rem;
    margin-left: 0;
}

body.rtl .close {
    float: left;
}


body.rtl  .breadcrumb-item + .breadcrumb-item::before {
    float: right;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

}